// i18next-extract-mark-ns-start markets-motorsport-hydrogen

import { MARKETS_CAPABILITIES_HYDROGEN_NAV, MOTORSPORT_NAV, HYDROGEN_PRODUCTS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorsportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);
	const vulcan = getImage(data.vulcan.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Simulating fuel cells and hydrogen combustion</h2>
						<p>
							With stricter global emissions restrictions and target deadlines looming, manufacturers are facing ever increasing challenges to make
							motorsport categories that meet the new environmental requirements for net zero emissions.
							Whilst the development of electric and hybrid vehicles offers options for low emission races, many governments are investing in researching
							a new hydrogen economy which is to the benefit of motorsport with new hydrogen combustion Le Mans cars previewed this year.
						</p>
						<p>
							In the initial stages of hydrogen engine development, physical prototype testing is extremely expensive, requiring virtual software tools
							to model both hydrogen fuel cell systems and hydrogen combustion engines, answering the ‘What ifs’ of new hydrogen technologies from
							hydrogen flame speed simulation to fuel cell humidity control, cooling and selection of components depending on the type of hydrogen being employed.
						</p>

						<p>
							For hydrogen combustion the following toolsets are available:
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							WAVE is a 1D Computational Fluid Dynamics (CFD) tool that solves the Navier-Stokes equations governing the transfer of mass,
							momentum, and energy for compressible gas flows, and includes sub-models for combustion and emissions.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							VECTIS is a 3D CFD tool that uses cutting edge kinetics, flame propagation and emissions models
							for conventional hydrocarbons, e-fuels and synthetic fuels including hydrogen using a unique thermo-diffusive model.
						</p>
					</Trans>

					{vulcan && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vulcan}
								alt="FEARCE-Vulcan"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_4">
						<p>
							FEARCE-Vulcan uses a unique iterative Finite Element solution that considers all the heat paths in detail to deliver a reliable
							and accurate predictive tool for conclusive structural investigations for hydrogen-fuelled engines.
						</p>

						<p>
							For modelling fuel cell and associated systems, the following toolsets are available:
						</p>
					</Trans>

					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_5">
						<p>
							IGNITE, a complete physics-based vehicle systems modelling simulation package. With a library system of building blocks,
							the user can quickly and accurately model complete fuel cell systems, analysing parameters around performance and efficiency.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_6">
						<p>
							VECTIS is a 3D Computational Fluid Dynamics (CFD) to optimise the cooling of battery packs for maximum thermal performance.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORSPORT_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{MARKETS_CAPABILITIES_HYDROGEN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{HYDROGEN_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<p> <small>Photo: motorsportimages.com</small></p>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorsportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorsport-hydrogen", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorsport/MotorsportImages_1920x640_EnduranceRacing_cropped.jpg" }) {
			...imageBreaker
		}
		ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
			...imageBreaker
		}
		wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
			...imageBreaker
		}
		vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
			...imageBreaker
		}
		vulcan: file(relativePath: { eq: "content/rs_fearce_vulcan.png" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { markets: { in: "Motorsport" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
